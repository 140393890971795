import React from 'react';

function SvgComponent(props) {
  return (
    <svg width={266} height={227} viewBox="0 0 266 227" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)" fill="#fff">
        <path d="M234.417 192.059a10.376 10.376 0 00-9.16 4.008 10.377 10.377 0 00-1.569 9.875l2.89 8.097a6.55 6.55 0 002.546 3.266 6.559 6.559 0 003.991 1.106 6.555 6.555 0 003.863-1.494 6.549 6.549 0 002.212-3.503l.023-.093a12.962 12.962 0 016.343-8.144 6.552 6.552 0 002.832-3.022 6.554 6.554 0 00.471-4.116 6.548 6.548 0 00-2.075-3.584 6.552 6.552 0 00-3.803-1.641l-8.564-.755zM221.608 208.618c.424-1.416.186-2.926-.673-4.252-.817-1.261-2.108-2.207-3.636-2.664-1.528-.456-3.127-.374-4.502.232-1.445.636-2.473 1.767-2.896 3.182-.852 2.845 1.081 5.947 4.31 6.915 2.573.767 5.201-.048 6.594-1.85a4.8 4.8 0 00.803-1.563zM222.626 198.027c2.119-2.742 1.302-6.937-1.823-9.352s-7.392-2.149-9.511.593c-2.119 2.742-1.301 6.938 1.824 9.353s7.391 2.149 9.51-.594zM230.656 190.514v-.001a5.1 5.1 0 001.197-1.121c1.546-2 1.596-5.074-.066-7.546-.957-1.424-2.34-2.448-3.895-2.884-1.628-.456-3.246-.222-4.558.66-1.312.882-2.139 2.293-2.33 3.972-.183 1.605.243 3.272 1.201 4.695 2.018 3.002 5.81 4 8.451 2.225zM238.089 179.529h-.001c-2.859.812-4.439 4.108-3.523 7.349.919 3.241 3.991 5.22 6.849 4.412a4.914 4.914 0 002.558-1.753c1.122-1.451 1.548-3.534.966-5.596-.919-3.241-3.991-5.22-6.849-4.412z" />
      </g>
      <g clipPath="url(#prefix__clip1)" fill="#fff">
        <path d="M169.443 146.085a10.38 10.38 0 00-9.161 4.007 10.379 10.379 0 00-1.568 9.875l2.89 8.098a6.55 6.55 0 002.546 3.266 6.551 6.551 0 003.991 1.105 6.548 6.548 0 003.863-1.494 6.546 6.546 0 002.212-3.502l.023-.093a12.962 12.962 0 016.343-8.145 6.558 6.558 0 002.832-3.022 6.551 6.551 0 00.471-4.115 6.545 6.545 0 00-2.075-3.584 6.542 6.542 0 00-3.803-1.641l-8.564-.755zM156.634 162.643c.424-1.416.185-2.926-.673-4.251-.817-1.261-2.108-2.208-3.636-2.665-1.528-.456-3.127-.373-4.502.232-1.445.637-2.473 1.767-2.896 3.182-.852 2.846 1.081 5.947 4.31 6.915 2.573.768 5.201-.048 6.593-1.849.356-.46.631-.985.804-1.564zM157.652 152.053c2.119-2.742 1.301-6.938-1.823-9.353-3.125-2.415-7.392-2.149-9.511.594-2.119 2.742-1.301 6.937 1.824 9.353 3.125 2.414 7.391 2.148 9.51-.594zM165.682 144.539a5.141 5.141 0 001.197-1.121c1.546-2 1.596-5.074-.066-7.547-.957-1.423-2.34-2.447-3.895-2.883-1.628-.457-3.246-.223-4.558.659-1.312.882-2.139 2.293-2.33 3.973-.183 1.604.243 3.271 1.2 4.694 2.019 3.003 5.811 4.001 8.452 2.225zM173.115 133.554l-.002.001c-2.858.811-4.438 4.107-3.522 7.349.919 3.24 3.991 5.219 6.848 4.411a4.911 4.911 0 002.559-1.752c1.122-1.452 1.548-3.535.966-5.597-.919-3.24-3.991-5.219-6.849-4.412z" />
      </g>
      <g clipPath="url(#prefix__clip2)" fill="#fff">
        <path d="M103.443 91.84a10.377 10.377 0 00-9.16 4.008 10.376 10.376 0 00-1.569 9.875l2.89 8.098a6.552 6.552 0 002.546 3.266 6.552 6.552 0 003.991 1.105 6.555 6.555 0 003.863-1.494 6.546 6.546 0 002.212-3.502l.023-.093a12.962 12.962 0 016.343-8.145 6.548 6.548 0 002.832-3.022 6.552 6.552 0 00.471-4.115 6.547 6.547 0 00-2.075-3.585 6.55 6.55 0 00-3.803-1.64l-8.564-.755zM90.634 108.399c.424-1.416.186-2.926-.673-4.251-.817-1.262-2.108-2.208-3.636-2.665-1.528-.456-3.127-.374-4.502.232-1.445.636-2.473 1.767-2.896 3.182-.852 2.846 1.081 5.947 4.31 6.915 2.573.768 5.201-.048 6.594-1.85.355-.46.63-.984.803-1.563zM91.652 97.809c2.12-2.743 1.302-6.938-1.823-9.353s-7.392-2.149-9.511.593c-2.12 2.743-1.301 6.938 1.824 9.354 3.125 2.414 7.39 2.148 9.51-.594zM99.682 90.295a5.124 5.124 0 001.197-1.121c1.546-2 1.596-5.074-.066-7.547-.957-1.423-2.34-2.447-3.895-2.883-1.628-.457-3.246-.223-4.558.659-1.312.882-2.14 2.293-2.33 3.972-.183 1.605.243 3.272 1.2 4.695 2.02 3.003 5.81 4 8.452 2.225zM107.115 79.31h-.002c-2.858.812-4.438 4.108-3.522 7.35.919 3.24 3.991 5.219 6.848 4.41a4.909 4.909 0 002.559-1.751c1.122-1.452 1.548-3.535.966-5.597-.919-3.24-3.991-5.22-6.849-4.412z" />
      </g>
      <g clipPath="url(#prefix__clip3)" fill="#fff">
        <path d="M35.443 39.84a10.377 10.377 0 00-9.16 4.008 10.376 10.376 0 00-1.569 9.875l2.89 8.098a6.55 6.55 0 002.546 3.266 6.552 6.552 0 003.991 1.105 6.553 6.553 0 003.863-1.494 6.55 6.55 0 002.212-3.502l.023-.093a12.965 12.965 0 016.343-8.145 6.559 6.559 0 002.832-3.022 6.55 6.55 0 00.471-4.115 6.55 6.55 0 00-2.075-3.585 6.551 6.551 0 00-3.803-1.64l-8.564-.755zM22.634 56.4c.424-1.417.186-2.927-.673-4.252-.817-1.262-2.108-2.208-3.636-2.665-1.528-.456-3.127-.374-4.502.232-1.445.636-2.473 1.767-2.896 3.182-.852 2.846 1.081 5.947 4.31 6.915 2.573.768 5.201-.048 6.594-1.85.355-.46.63-.984.803-1.563zM23.652 45.809c2.12-2.743 1.301-6.938-1.823-9.353-3.125-2.415-7.392-2.149-9.511.593-2.12 2.743-1.301 6.938 1.824 9.354 3.125 2.414 7.39 2.148 9.51-.594zM31.682 38.295a5.122 5.122 0 001.197-1.121c1.546-2 1.596-5.074-.066-7.547-.957-1.423-2.34-2.447-3.895-2.883-1.628-.457-3.246-.223-4.558.659-1.312.882-2.14 2.293-2.33 3.972-.183 1.605.243 3.272 1.2 4.695 2.02 3.002 5.81 4 8.452 2.225zM39.115 27.31h-.002c-2.858.812-4.438 4.108-3.522 7.35.919 3.24 3.991 5.219 6.848 4.41A4.913 4.913 0 0045 37.319c1.121-1.451 1.547-3.534.965-5.596-.919-3.24-3.991-5.22-6.85-4.412z" />
      </g>
      <g clipPath="url(#prefix__clip4)" fill="#fff">
        <path d="M240.443 115.085a10.38 10.38 0 00-9.161 4.007 10.379 10.379 0 00-1.568 9.875l2.89 8.098a6.55 6.55 0 002.546 3.266 6.551 6.551 0 003.991 1.105 6.548 6.548 0 003.863-1.494 6.546 6.546 0 002.212-3.502l.023-.093a12.962 12.962 0 016.343-8.145 6.558 6.558 0 002.832-3.022 6.551 6.551 0 00.471-4.115 6.545 6.545 0 00-2.075-3.584 6.542 6.542 0 00-3.803-1.641l-8.564-.755zM227.634 131.643c.424-1.416.185-2.926-.673-4.251-.817-1.261-2.108-2.208-3.636-2.665-1.528-.456-3.127-.373-4.502.232-1.445.637-2.473 1.767-2.896 3.182-.852 2.846 1.081 5.947 4.31 6.915 2.573.768 5.201-.048 6.593-1.849.356-.46.631-.985.804-1.564zM228.652 121.053c2.119-2.742 1.301-6.938-1.823-9.353-3.125-2.415-7.392-2.149-9.511.594-2.119 2.742-1.301 6.937 1.824 9.353 3.125 2.414 7.391 2.148 9.51-.594zM236.682 113.539a5.141 5.141 0 001.197-1.121c1.546-2 1.596-5.074-.066-7.547-.957-1.423-2.34-2.447-3.895-2.883-1.628-.457-3.246-.223-4.558.659-1.312.882-2.139 2.293-2.33 3.973-.183 1.604.243 3.271 1.2 4.694 2.019 3.003 5.811 4.001 8.452 2.225zM244.115 102.554l-.002.001c-2.858.811-4.438 4.107-3.522 7.349.919 3.24 3.991 5.219 6.848 4.411a4.911 4.911 0 002.559-1.752c1.122-1.452 1.548-3.535.966-5.597-.919-3.24-3.991-5.219-6.849-4.412z" />
      </g>
      <g clipPath="url(#prefix__clip5)" fill="#fff">
        <path d="M182.443 72.085a10.378 10.378 0 00-9.161 4.008 10.378 10.378 0 00-1.568 9.874l2.89 8.098a6.548 6.548 0 002.546 3.266 6.553 6.553 0 003.991 1.105 6.553 6.553 0 003.863-1.494 6.55 6.55 0 002.212-3.502l.023-.093a12.962 12.962 0 016.343-8.145 6.554 6.554 0 002.832-3.022 6.552 6.552 0 00.471-4.115 6.547 6.547 0 00-2.075-3.584 6.551 6.551 0 00-3.803-1.641l-8.564-.755zM169.634 88.644c.424-1.417.185-2.927-.673-4.252-.817-1.261-2.108-2.208-3.636-2.665-1.528-.456-3.127-.374-4.502.232-1.445.636-2.473 1.767-2.896 3.182-.852 2.846 1.081 5.947 4.31 6.915 2.573.768 5.201-.048 6.593-1.85.356-.46.631-.984.804-1.563zM170.652 78.053c2.119-2.742 1.301-6.938-1.823-9.353-3.125-2.415-7.392-2.149-9.511.594-2.119 2.742-1.301 6.938 1.824 9.353 3.125 2.414 7.391 2.148 9.51-.594zM178.682 70.539a5.121 5.121 0 001.197-1.121c1.546-2 1.596-5.074-.066-7.547-.957-1.423-2.34-2.447-3.895-2.883-1.628-.457-3.246-.223-4.558.66-1.312.881-2.139 2.292-2.33 3.972-.183 1.604.243 3.271 1.2 4.694 2.019 3.003 5.811 4 8.452 2.225zM186.115 59.554h-.002c-2.858.812-4.438 4.108-3.522 7.35.919 3.24 3.991 5.219 6.848 4.411a4.91 4.91 0 002.559-1.752c1.122-1.452 1.548-3.535.966-5.597-.919-3.24-3.991-5.22-6.849-4.412z" />
      </g>
      <g clipPath="url(#prefix__clip6)" fill="#fff">
        <path d="M107.443 25.085a10.377 10.377 0 00-9.16 4.008 10.376 10.376 0 00-1.569 9.874l2.89 8.098a6.547 6.547 0 002.546 3.266 6.553 6.553 0 003.991 1.105 6.553 6.553 0 003.863-1.494 6.55 6.55 0 002.212-3.502l.023-.093a12.962 12.962 0 016.343-8.145 6.554 6.554 0 002.832-3.022 6.552 6.552 0 00.471-4.115 6.547 6.547 0 00-2.075-3.585 6.551 6.551 0 00-3.803-1.64l-8.564-.755zM94.634 41.644c.424-1.417.186-2.927-.673-4.252-.817-1.261-2.108-2.208-3.636-2.665-1.528-.456-3.127-.373-4.502.232-1.445.636-2.473 1.767-2.896 3.182-.852 2.846 1.081 5.947 4.31 6.915 2.573.768 5.201-.048 6.594-1.85.355-.46.63-.984.803-1.563zM95.652 31.053c2.12-2.742 1.302-6.938-1.823-9.353s-7.392-2.149-9.511.594c-2.12 2.742-1.301 6.938 1.824 9.353 3.125 2.414 7.39 2.148 9.51-.594zM103.682 23.539a5.121 5.121 0 001.197-1.121c1.546-2 1.596-5.074-.066-7.547-.957-1.423-2.34-2.447-3.895-2.883-1.628-.457-3.246-.223-4.558.66-1.312.881-2.14 2.292-2.33 3.972-.183 1.604.243 3.271 1.2 4.694 2.02 3.003 5.811 4 8.452 2.225zM111.115 12.554h-.002c-2.858.812-4.438 4.108-3.522 7.35.919 3.24 3.991 5.219 6.848 4.411a4.91 4.91 0 002.559-1.752c1.122-1.452 1.548-3.535.966-5.597-.919-3.24-3.991-5.22-6.849-4.412z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            transform="rotate(-52.302 198.974 200.833)"
            fill="#fff"
            d="M198.974 200.833h42.792v42.792h-42.792z"
          />
        </clipPath>
        <clipPath id="prefix__clip1">
          <path
            transform="rotate(-52.302 134 154.858)"
            fill="#fff"
            d="M134 154.858h42.792v42.792H134z"
          />
        </clipPath>
        <clipPath id="prefix__clip2">
          <path
            transform="rotate(-52.302 68 100.614)"
            fill="#fff"
            d="M68 100.614h42.792v42.792H68z"
          />
        </clipPath>
        <clipPath id="prefix__clip3">
          <path
            transform="rotate(-52.302 0 48.614)"
            fill="#fff"
            d="M0 48.614h42.792v42.792H0z"
          />
        </clipPath>
        <clipPath id="prefix__clip4">
          <path
            transform="rotate(-52.302 205 123.858)"
            fill="#fff"
            d="M205 123.858h42.792v42.792H205z"
          />
        </clipPath>
        <clipPath id="prefix__clip5">
          <path
            transform="rotate(-52.302 147 80.858)"
            fill="#fff"
            d="M147 80.858h42.792v42.792H147z"
          />
        </clipPath>
        <clipPath id="prefix__clip6">
          <path
            transform="rotate(-52.302 72 33.858)"
            fill="#fff"
            d="M72 33.858h42.792V76.65H72z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
