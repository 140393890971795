import React from 'react';
import numeral from 'numeral';

const CoverageOutputDesktop = ({
  outOfPocketCost,
  insuranceCovers,
  remainingReimbursement,
}) => {
  return (
    <div
      style={{
        boxShadow: '0px 0px 40px rgba(97, 99, 0, 0.13)',
        borderRadius: 10,
        padding: '74px 39px 30px',
        position: 'relative',
      }}
    >
      <img
        src="/images/cat-dog-perched.png"
        alt="Cat and dog"
        style={{ position: 'absolute', right: 10, top: -63 }}
      />
      <div
        style={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '20px',
          lineHeight: '24px',
          marginBottom: 6,
          textTransform: 'uppercase',
        }}
      >
        Coverage Calculator
      </div>
      <p
        style={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '18px',
          lineHeight: '22px',
          marginBottom: 17,
        }}
      >
        Use our coverage calculator to estimate your out-of-pocket cost and the
        reimbursement amount from the insurance provider.
      </p>
      <div
        style={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '24px',
          marginBottom: 13,
        }}
      >
        How Much Will I Pay?
      </div>
      <div
        style={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '30px',
          lineHeight: '36px',
          marginBottom: 25,
        }}
      >
        {numeral(outOfPocketCost).format('$0,0[.]00')}
      </div>
      <div
        style={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '24px',
          marginBottom: 13,
        }}
      >
        How Much Will My Insurance Cover
      </div>
      <div
        style={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '30px',
          lineHeight: '36px',
          marginBottom: 25,
        }}
      >
        {numeral(insuranceCovers).format('$0,0[.]00')}
      </div>
      <div
        style={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '24px',
          marginBottom: 13,
        }}
      >
        Remaining Reimbursement
      </div>
      <div
        style={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '30px',
          lineHeight: '36px',
          marginBottom: 25,
        }}
      >
        {remainingReimbursement === 'Unlimited'
          ? 'Unlimited'
          : numeral(remainingReimbursement).format('$0,0[.]00')}
      </div>
    </div>
  );
};

export default CoverageOutputDesktop;
