import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pawprints from './Pawprints';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    backgroundColor: '#f4f4f4',
    padding: '80px 50px 70px',

    [theme.breakpoints.up('sm')]: {
      padding: '80px 107px 70px',
    },
  },
  emoji: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '80px',
    lineHeight: '94px',
    marginBottom: 13,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '150%',
    color: '#494747',
    position: 'absolute',
    margin: 0,
    top: -30,

    [theme.breakpoints.up('sm')]: {
      top: -30,
      fontSize: '46px',
      lineHeight: '55px',
    },
  },
  advantageTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '31px',
    letterSpacing: '-1px',
    color: '#212121',
    margin: 0,
    marginBottom: 36,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  advantageDescription: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#666666',
    opacity: 0.8,
    marginTop: 0,
  },
}));

const WhyChooseUs = () => {
  const classes = useStyles({});
  return (
    <div className={classes.container}>
      <Pawprints style={{ position: 'absolute', right: -30, top: -30 }} />
      <h2 className={classes.title}>Why choose us?</h2>
      <Grid container spacing={6}>
        <Grid item md={4}>
          <div className={classes.emoji}>⏰</div>
          <h3 className={classes.advantageTitle}>Save Time</h3>
          <p className={classes.advantageDescription}>
            We help you save a tremendous amount of time by aggregating and
            presenting you with the quotes from all major pet insurance
            providers.
          </p>
        </Grid>
        <Grid item md={4}>
          <div className={classes.emoji}>✍️</div>
          <h3 className={classes.advantageTitle}>Personalized Experience</h3>
          <p className={classes.advantageDescription}>
            The quotes are all personalized based on your pet's age, breed and
            gender to ensure you're getting the best options possible that meet
            your needs.
          </p>
        </Grid>
        <Grid item md={4}>
          <div className={classes.emoji}>😍</div>
          <h3 className={classes.advantageTitle}>Transparency</h3>
          <p className={classes.advantageDescription}>
            Each quote includes a comprehensive calculation so you know how much
            you'll be paying in case of accidents or illness.
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default WhyChooseUs;
