import React from 'react';
import numeral from 'numeral';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#615f5f',
    marginBottom: 17,
  },
  outputContainer: {
    border: '1px solid #d5d5d5',
    borderLeft: 0,
    borderRight: 0,
    paddingTop: 14,
    paddingBottom: 14,
  },
  group: {
    marginTop: 10,
    marginBottom: 10,
  },
  question: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '17px',
  },
  answer: {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '24px',
  },
});

const CoverageOutput = ({
  outOfPocketCost,
  insuranceCovers,
  remainingReimbursement,
}) => {
  const classes = useStyles({});

  return (
    <div>
      <p className={classes.description}>
        Use our coverage calculator to estimate your out-of-pocket cost and the
        reimbursement amount from the insurance provider.
      </p>
      <div className={classes.outputContainer}>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.group}
        >
          <span className={classes.question}>How Much Will I Pay?</span>
          <span className={classes.answer}>
            {numeral(outOfPocketCost).format('$0,0[.]00')}
          </span>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.group}
        >
          <span className={classes.question}>
            How Much Will My Insurance Cover
          </span>
          <span className={classes.answer}>
            {numeral(insuranceCovers).format('$0,0[.]00')}
          </span>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.group}
        >
          <span className={classes.question}>Remaining Reimbursement</span>
          <span className={classes.answer}>
            {remainingReimbursement === 'Unlimited'
              ? 'Unlimited'
              : numeral(remainingReimbursement).format('$0,0[.]00')}
          </span>
        </Box>
      </div>
    </div>
  );
};

export default CoverageOutput;
