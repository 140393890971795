import color from 'color';
import numeral from 'numeral';
import * as React from 'react';
import NumberFormat from 'react-number-format';

import { Button, OutlinedInput, StandardProps, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import InformationTooltip from '../InformationTooltip';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '17px',
    margin: '14px 0',
    [theme.breakpoints.up('sm')]: {
      marginTop: 40,
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  toggleGroup: {
    width: '100%',
  },
  toggleGroupInnerContainer: {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.06)',
  },
  textInput: {
    height: 36,
    width: '100%',
    fontSize: '14px',
    lineHeight: '17px',
    borderColor: '#cecece',
    // marginBottom: 15,
    [theme.breakpoints.up('sm')]: {
      height: 46,
      width: 300,
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
}));

const StyledToggleButton = withStyles((theme) => ({
  root: {
    width: '20%',
    maxWidth: 120,
    height: 36,
    color: '#000000',
    borderColor: '#e8e8e8',
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },

    '&$selected': {
      backgroundColor: '#ff6f00',
      color: '#ffffff',
      boxShadow: 'inset 0px 0px 11px rgba(157, 3, 3, 0.24)',
      '&:hover': {
        backgroundColor: color('#ff6f00').darken(0.2).hex(),
      },
    },
  },
  selected: {},
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '13px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
}))(ToggleButton);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

interface Props
  extends StandardProps<React.HTMLAttributes<HTMLDivElement>, ''> {
  annualDeductible: number;
  annualDeductibles: number[];
  setAnnualDeductible: (number) => void;
  reimbursementPercentage: number;
  reimbursementPercentages: number[];
  setReimbursementPercentage: (number) => void;
  annualReimbursementLimit: number;
  annualReimbursementLimits: number[];
  setAnnualReimbursementLimit: (number) => void;
  vetBill: number;
  setVetBill: (number) => void;
}

const CalculatorInput = ({
  annualDeductible,
  annualDeductibles,
  setAnnualDeductible,
  reimbursementPercentage,
  reimbursementPercentages,
  setReimbursementPercentage,
  annualReimbursementLimit,
  annualReimbursementLimits,
  setAnnualReimbursementLimit,
  vetBill,
  setVetBill,
  className,
  style,
}: Props) => {
  const classes = useStyles({});

  return (
    <div className={className} style={style}>
      <label className={classes.label}>Your Vet Bill</label>
      <OutlinedInput
        onChange={(event) =>
          setVetBill(event.target.value ? parseFloat(event.target.value) : 0)
        }
        labelWidth={0}
        placeholder="$"
        value={vetBill}
        className={classes.textInput}
        inputComponent={NumberFormatCustom}
      />
      <label className={classes.label}>
        Annual Deductible
        <InformationTooltip title="The amount you pay before insurance covers the claim." />
      </label>
      <ToggleButtonGroup
        value={annualDeductible}
        exclusive
        onChange={(event, value) => setAnnualDeductible(value)}
        aria-label="annual deductible"
        size="large"
        className={classes.toggleGroup}
      >
        {annualDeductibles.map((value) => (
          <StyledToggleButton key={value} value={value} aria-label={`${value}`}>
            {numeral(value).format('$0,0')}
          </StyledToggleButton>
        ))}
      </ToggleButtonGroup>
      <label className={classes.label}>
        Reimbursement{' '}
        <InformationTooltip title="The amount insurance pays after deductibles." />
      </label>
      <ToggleButtonGroup
        value={reimbursementPercentage}
        exclusive
        onChange={(event, value) => setReimbursementPercentage(value)}
        aria-label="reimbursement"
        size="large"
        className={classes.toggleGroup}
      >
        {reimbursementPercentages.map((value) => (
          <StyledToggleButton key={value} value={value} aria-label={`${value}`}>
            {numeral(value).format('0%')}
          </StyledToggleButton>
        ))}
      </ToggleButtonGroup>
      <label className={classes.label}>
        Annual Reimbursement Limit{' '}
        <InformationTooltip title="The maximum amount insurance will cover." />
      </label>
      <ToggleButtonGroup
        value={annualReimbursementLimit}
        exclusive
        onChange={(event, value) => setAnnualReimbursementLimit(value)}
        aria-label="reimbursement"
        size="large"
        className={classes.toggleGroup}
      >
        {annualReimbursementLimits.map((value) => (
          <StyledToggleButton key={value} value={value} aria-label={`${value}`}>
            {numeral(value).format('$0,0')}
          </StyledToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default CalculatorInput;
