import React, { CSSProperties } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';

import {
  calculateInsuranceCoverage,
  calculateOutOfPocketCost,
  calculateRemainingReimbursement,
} from '../../utils/calculateQuote';
import CalculatorInput from './CalculatorInput';
import CoverageOutputDesktop from './CoverageOutputDesktop';
import CoverageOutputMobile from './CoverageOutputMobile';
import CalculatorIcon from '../assets/Calculator';

interface Props {
  style?: CSSProperties;
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    paddingTop: 52,
    paddingBottom: 87,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 195,
      paddingBottom: 195,
    },
  },
  calculatorContainer: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 100,
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#3A3A3A',
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
      lineHeight: '43px',
      color: '#000000',
    },
  },
  calculatorIcon: {
    marginRight: 8,

    [theme.breakpoints.down('xs')]: {
      height: 20,
      width: 20,
    },
  },
}));

const annualDeductibles = [1000, 750, 500, 250, 200];

const reimbursements = [0.7, 0.8, 0.9];

const annualReimbursementLimits = [5000, 8000, 10000, 15000, 20000];

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const InsuranceCalculator = ({ style }: Props) => {
  const classes = useStyles({});

  const [annualDeductible, setAnnualDeductible] = React.useState(500);
  const [reimbursementPercentage, setReimbursementPercentage] = React.useState(
    0.8,
  );
  const [
    annualReimbursementLimit,
    setAnnualReimbursementLimit,
  ] = React.useState(10000);
  const [vetBill, setVetBill] = React.useState(8000);

  const inputs = {
    vetBill,
    deductible: annualDeductible,
    reimbursementPercentage,
    reimbursementLimit: annualReimbursementLimit,
  };

  const outOfPocketCost = calculateOutOfPocketCost(inputs);
  const insuranceCovers = calculateInsuranceCoverage(inputs);
  const remainingReimbursement = calculateRemainingReimbursement(inputs);

  const calculatorInputs = {
    annualDeductible,
    annualDeductibles,
    setAnnualDeductible,
    reimbursementPercentage,
    reimbursementPercentages: reimbursements,
    setReimbursementPercentage,
    annualReimbursementLimit,
    annualReimbursementLimits,
    setAnnualReimbursementLimit,
    vetBill,
    setVetBill,
  };

  return (
    <div className={classes.container} style={style}>
      <h3 className={classes.title}>
        <CalculatorIcon className={classes.calculatorIcon} />
        Pet Insurance Calculator
      </h3>
      <Hidden smUp>
        <CoverageOutputMobile
          outOfPocketCost={outOfPocketCost}
          remainingReimbursement={remainingReimbursement}
          insuranceCovers={insuranceCovers}
        />
      </Hidden>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>
          <CalculatorInput
            {...calculatorInputs}
            className={classes.calculatorContainer}
          />
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} md={5}>
            <CoverageOutputDesktop
              outOfPocketCost={outOfPocketCost}
              remainingReimbursement={remainingReimbursement}
              insuranceCovers={insuranceCovers}
            />
          </Grid>
        </Hidden>
      </Grid>
      <Hidden smUp>
        <img
          src="/images/cat-dog-perched.png"
          alt="Cat and dog"
          style={{ position: 'absolute', right: 0, bottom: -15, width: 156 }}
        />
      </Hidden>
    </div>
  );
};

export default InsuranceCalculator;
