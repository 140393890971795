import color from 'color';
import Head from 'next/head';
import Link from 'next/link';
import React from 'react';

import {
    Button, Container, FormControl, Hidden, OutlinedInput, withStyles
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Footer from '../components/Footer';
import InsuranceCalculator from '../components/InsuranceCalculator';
import Nav from '../components/NewNav';
import WhyChooseUs from '../components/WhyChooseUs';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    paddingTop: 100,
    paddingBottom: 128,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 120,
      paddingBottom: 200,
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '155%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
      maxWidth: 571,
    },
  },
  heroImage: {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    right: '-30%',

    [theme.breakpoints.up('lg')]: {
      right: -220,
    },
  },
  inputControl: {
    display: 'block',
    marginBottom: 10,

    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
      marginBottom: 0,
    },
  },
  input: {
    backgroundColor: '#ffffff',
    border: `2px solid ${theme.palette.primary.main}`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    height: 46,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 300,
    },
  },
  subscribeButton: {
    height: 46,
    borderRadius: 4,

    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 150,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  quoteButton: {
    height: 46,
    borderRadius: 4,

    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 224,
    },
  },
  quoteSubtext: {
    marginTop: 10,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '155%',
    width: '100%',
    textAlign: 'center',

    [theme.breakpoints.up('sm')]: {
      width: 224,
    },
  },
  nav: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '90px',
      marginBottom: '60px',
    },
  },
  heroContainer: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minHeight: 500,
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const StyledInput = withStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: 3,
    height: 46,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 300,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    '& $notchedOutline': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
      top: '-4.5px',
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: color(theme.palette.primary.main).darken(0.2).hex(),
      '@media (hover: none)': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  notchedOutline: {},
}))(OutlinedInput);

const Home = () => {
  const classes = useStyles();

  const enableFreeQuote = true;

  return (
    <>
      <Head>
        <title>Breezy - Find & Compare Pet Insurance Quotes</title>
        <meta
          name="description"
          content="Quickly help you find the best and most affordable insurance for your pets."
        />
      </Head>
      <Nav className={classes.nav} logoDesktopSize={48} scaleDesktopLogo />
      <Container className={classes.heroContainer}>
        <Hidden smDown>
          <img
            src="/images/main-woman-pets.png"
            alt="Woman with dog and cat"
            className={classes.heroImage}
          />
        </Hidden>
        <div className={classes.contentContainer}>
          <h1 className={classes.title}>
            Quickly help you find the best and most affordable insurance for
            your pets.
          </h1>
          {enableFreeQuote ? (
            <>
              <Link passHref href="/quote">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  className={classes.quoteButton}
                >
                  Get Free Quote
                </Button>
              </Link>
              <div className={classes.quoteSubtext}>No email required!</div>
            </>
          ) : (
            <>
              <form
                action="https://sobreezy.us4.list-manage.com/subscribe/post?u=848ea9c1bc14c40bd90f5b237&amp;id=406be5ef1f"
                method="post"
                style={{ marginBottom: '12px' }}
              >
                <FormControl
                  variant="outlined"
                  className={classes.inputControl}
                >
                  <StyledInput
                    labelWidth={0}
                    placeholder="Email"
                    name="EMAIL"
                  />
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  className={classes.subscribeButton}
                >
                  Subscribe Now
                </Button>
              </form>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '155%',
                }}
              >
                Sign up now to get updates when we launch!
              </span>
            </>
          )}
        </div>
      </Container>
      <div style={{ backgroundColor: '#ffffff', paddingTop: 140 }}>
        <Container>
          <WhyChooseUs />
        </Container>
        <Container>
          <InsuranceCalculator />
        </Container>
      </div>
      <div style={{ display: 'none' }}>Pepperjam Verification</div>
      <Footer />

      <style jsx global>
        {`
          body {
            background-color: #f4d87d !important;
          }
        `}
      </style>
    </>
  );
};

export default Home;
